<template>
    <div class="qianghua">
  
      <div class="content">
        <div class="nandu">
          <label for="">难度等级：</label>
          <van-rate disabled disabled-color="#F9C200" v-model="topicData['难度']"/>
        </div>
  
  
        <div class="content-info">
          <div id="topicMainHtml">
            <div class="content-info-box" id="topicMain" v-if="topicData['例题']">
              <div class="pl_15 pr_15 pt_12 tibox ta_l fsz_18">
                <template v-for="(item,n) in topicData['例题']">
                  <span :key="n" v-if="item[0]=='str'" class="item">{{ item[ 1 ] }}</span>
                  <span :key="n" v-else-if="item[0]=='tex'" class="tex_item" :id="'tex_item_'+n"></span>
                  <span :key="n" v-else-if="item[0]=='img'" class="tex_item" :id="'tex_item_'+n">
                                    <img :src="'data:image/png;base64,'+item[1]" alt="">
                                  </span>
                </template>
              </div>
  
              <div class="option" v-if="topicData['答题方式'] == '单选'">
                <ul v-show="!isSubmit">
                  <template v-for="(item,n) in optionsListHtml">
                    <li v-if="active == '' || active == 'on'" :class="checkNum==n ? active: ''" :key="n"
                        @click="optClick(n)">
                      {{ xuanXiang[ n ] }}<span :class="'option_span option_span_'+n" v-html="item"></span>
                    </li>
                    <li v-else-if="active=='err'" :class="topicData['解'][n]?'':checkNum==n?'err':''" :key="n">
                      {{ xuanXiang[ n ] }}<span :class="'option_span option_span_'+n" v-html="item"></span>
                    </li>
                    <li v-else-if="active=='succ'" :class="checkNum==n ? active: ''" :key="n">
                      {{ xuanXiang[ n ] }}<span :class="'option_span option_span_'+n" v-html="item"></span>
                    </li>
                  </template>
                </ul>
                <ul v-show="isSubmit">
                  <template v-for="(item,n) in optionsListHtml">
                    <li v-if="active=='err' && checkNum==n" :class="topicData['解'][n]?'':checkNum==n?'err':''" :key="n">
                      <span :class="'option_span option_span_'+n" v-html="item"></span>
                    </li>
                    <li v-else-if="active=='succ' && checkNum==n" :class="checkNum==n ? active: ''" :key="n">
                      <span :class="'option_span option_span_'+n" v-html="item"></span>
                    </li>
                  </template>
                </ul>
              </div>
  
              <div class="option" v-if="topicData['答题方式'] == '多选'">
                <ul>
                  <template v-for="(item,n) in topicData['选项']">
                    <li v-if="!submitStatu && checkMoreArr.length>0 && checkMoreArr[n].isChecked"
                        :class="checkMoreArr[n].isChecked?'on':''" :key="n" @click="optClick(n)">
                      <span :class="'option_span option_span_'+n"></span>
                    </li>
                    <li v-else-if="submitStatu && checkMoreArr[n].isChecked && checkMoreArr[n].answer" class="succ"
                        :key="n">
                      <span :class="'option_span option_span_'+n"></span>
                    </li>
                    <li v-else-if="submitStatu && checkMoreArr[n].isChecked && !checkMoreArr[n].answer" class="err"
                        :key="n">
                      <span :class="'option_span option_span_'+n"></span>
                    </li>
                    <li v-else-if="submitStatu && !checkMoreArr[n].isChecked && checkMoreArr[n].answer" class="no"
                        :key="n">
                      <span :class="'option_span option_span_'+n"></span>
                    </li>
                    <li v-else :key="n" @click="optClick(n)">
                      <span :class="'option_span option_span_'+n"></span>
                    </li>
                  </template>
                </ul>
              </div>
              <div class="option" v-if="topicData['答题方式'] === '解答'"  v-show="!isSubmit">
                <ul>
                  <p class="answer-alert">请在草稿纸作答后点击提交校对</p>
                  <div class="btm-con dis_flex dis_sb" v-if="!showAnswerBtn">
                    <span class="btn-list " style="width:200px;margin-right: 12px;" @click="submitAnswer">提  交</span>
                    <van-popover v-model="showPopover" trigger="click" placement="bottom-end"
                                 :close-on-click-outside="false">
                      <div class="tishi-con">
                        <p class="p1">提示：</p>
                        <ul>
                          <li :class="(tishiShowList.includes(n) || n==0)?'':'on'" v-for="(item,n) in tishiList" :key="n"
                              @click="tishi(n)">
                            <template v-if="n==0">
                              <span v-html="item"></span>
                            </template>
                            <template v-else>
                              <p v-if="!tishiShowList.includes(n)">点击显示提示{{ number[ n ] }}</p>
                              <span v-show="tishiShowList.includes(n)" v-html="item"></span>
                            </template>
                          </li>
                        </ul>
                      </div>
                      <template #reference>
                                  <span class="btn-list sub" :class="showPopover?'on':''">
                                      提示
                                  </span>
                      </template>
                    </van-popover>
                  </div>
                  <div class="btm-con dis_flex dis_sb" v-else>
                    <span class="btn-list " style="width:200px;margin-right: 12px; background: #15C392;" @click="submit(true)">过 关</span>
                    <span class="btn-list " style="width:200px;margin-right: 12px; background: #FF4236;" @click="submit(false)">不过关</span>
                  </div>
                </ul>
                <ul v-show="isSubmit">
                  <template v-for="(item,n) in optionsListHtml">
                    <li v-if="active=='err' && checkNum==n" :class="topicData['解'][n]?'':checkNum==n?'err':''" :key="n">
                      <span :class="'option_span option_span_'+n" v-html="item"></span>
                    </li>
                    <li v-else-if="active=='succ' && checkNum==n" :class="checkNum==n ? active: ''" :key="n">
                      <span :class="'option_span option_span_'+n" v-html="item"></span>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
          <template v-if="!isSubmit && topicData['答题方式'] !== '解答'">
            <div class="btm-con dis_flex dis_sb" v-if="topicResult === ''">
              <span class="btn-list " style="width:200px;margin-right: 12px;" @click="submit">提  交</span>
              <van-popover v-model="showPopover" trigger="click" placement="bottom-end" :close-on-click-outside="false">
                <div class="tishi-con">
                  <p class="p1">提示：</p>
                  <ul>
                    <li :class="(tishiShowList.includes(n) || n==0)?'':'on'" v-for="(item,n) in tishiList" :key="n"
                        @click="tishi(n)">
                      <template v-if="n==0">
                        <span v-html="item"></span>
                      </template>
                      <template v-else>
                        <p v-if="!tishiShowList.includes(n)">点击显示提示{{ number[ n ] }}</p>
                        <span v-show="tishiShowList.includes(n)" v-html="item"></span>
                      </template>
                    </li>
                  </ul>
                </div>
                <template #reference>
                                  <span class="btn-list sub" :class="showPopover?'on':''">
                                      提示
                                  </span>
                </template>
              </van-popover>
            </div>
          </template>
          <div v-if="isSubmit && topicData['答题方式'] !== '解答'" class="ta_c">
            <i v-if="topicData['答题方式'] == '单选'" class="iconfont iconGroup-" @click="isSubmit = !isSubmit"></i>
          </div>
        </div>
  
  
        <div v-show="topicResult !=='' && !showAnswerBtn" class="fenbu-con mt_12 ">
          <van-collapse v-model="activeNames">
            <template v-for="(item,n) in topicData['分步']">
              <!-- <van-collapse-item :key="n" :title="'目标'+ number[n] +'：'+item['步骤']" :name="n"> -->
              <van-collapse-item :key="n" :name="n" :is-link="false">
                <template #title>
                  <div>
                    <!-- <span>目标 {{number[n]}} ：{{item['步骤']}}</span>     -->
                    <!-- <span>目标 {{ number[n] }}: </span> -->
                    <span class="bbuzhou" :id="'bbuzhou_'+n"></span>
                  </div>
                </template>
                <div class="step-info">
                  <div class="step-info-list">
                    <!-- <p class="p2">步骤{{number[n]}}：</p> -->
                    <div class="ta_l">
                      <template v-for="(array,num) in item['解析']">
                        <template v-for="(list_1,num_1) in array">
                          <span :key="num+num_1+'_str'" v-if="list_1[0]=='str'"
                                class="item_jiexi item">{{ list_1[ 1 ] }}</span>
                          <span :key="num+num_1+'_tex'" v-else-if="list_1[0]=='tex'" class="item_jiexi tex_item"
                                :id="'step_list_'+n+'_'+num+'_'+num_1"></span>
                          <span :key="num+num_1+'_tex'" v-else-if="list_1[0]=='img'" class="item_jiexi tex_item">
                                                    <img :src="'data:image/png;base64,'+list_1[1]" alt="">
                                                  </span>
                        </template>
                      </template>
                    </div>
                  </div>
                  <!-- <div class="step-info-list on" :key="item['答案']+n">
                      <div class="title">结果：</div>
                      <div class="result ta_c">
                          <div class="result-jie" :id="'resultJie_'+n"></div>
                      </div>
                  </div> -->
                </div>
              </van-collapse-item>
            </template>
  
          </van-collapse>
  
          <div class="btn-s">
            <div class="btn-list" @click="shooAllStep">{{ text1 }}</div>
            <span class="btn-list-n" @click="nextTopic">下一题</span>
          </div>
  
        </div>
  
        <div class="expand-banner" v-show="topicResult !=='' && !showAnswerBtn">
          <div>
            <div class="expand-banner__header">
              <div />
              <p>举一反三</p>
            </div>
  
            <div class="expand-banner__desc">
              <div v-html="renderExpandInfo('举一反三')" />
            </div>
          </div>
  
          <div style="margin-top: 12px">
            <div class="expand-banner__header">
              <div />
              <p>思路形成</p>
            </div>
  
            <div class="expand-banner__desc">
              <div v-html="renderExpandInfo('思路形成')" />
            </div>
          </div>
        </div>
      </div>
  
  
      <!-- 导学 -->
      <van-dialog v-model:show="showDx" title="导学">
        <div class="daoxuan">
          <div id="daoxuanHtml"></div>
        </div>
      </van-dialog>
      <!-- 考纲 -->
      <van-dialog v-model:show="showKg" title="考纲">
        <div class="daoxuan">
          <div class="p_20" id="kaogangHtml" v-if="navInfo" v-html="kaogangText"></div>
        </div>
      </van-dialog>
      <!-- 成功 -->
      <van-dialog v-model:show="showSucc" title="" show-cancel-button confirm-button-text="下一专题" cancel-button-text="取消"
                  :beforeClose="beforeClose">
        <div class="succ-bbox pl_28 pr_38 pt_25 pb_20">
          <img src="@/assets/img/img_12.jpg" alt="">
          <p class="p1 pt_30">成功完成“{{ $store.state.special.this_topic }}”</p>
          <div class="dis_flex dis_sb pt_24 pl_40 pr_40">
            <div class="item">
              <p class="p2">做题强化</p>
              <p class="p3">{{ jd_q.total_count }}<span>题</span></p>
            </div>
            <div class="item">
              <p class="p2">正确率</p>
              <p class="p3">{{ jd_q.ratio * 100 }} <span>%</span></p>
            </div>
          </div>
          <!-- <div class="btn-next on">下一专题</div>
          <div class="btn-next">再做几题</div> -->
        </div>
      </van-dialog>
  
    </div>
  </template>
  
  <script>
  // 有限题
  var $ = require("jquery");
  window.$ = $;
  let time_id, time_ids;
  
  import katex from 'katex';
  
  export default {
    name: 'special',
    data () {
      return {
        tishiList: [],
        tishiShowList: [],
        showPopover: false,
  
        jd_q: {},
        showSucc: false, // 完成后的弹框
        showDx: false,
        showKg: false,
        aa: false, // 做过题
  
        kaogangText: '',
        daoxuanHtml: '',
        activeName: '',
        showNum: 0,//左侧菜单展开那个
        leftInfo: {
          showNum: 0, //当前展开
          checkedNum: 0, // 当前选中
          data: '',
          level: 1, //难度
        },
        currentInfo: {}, // 当前页面的左侧菜单展示信息
  
        // topicData: {}, // 获取的题的内容
        specialName: '',//专题名称
        titleName: '', //标题名称
  
        active: '',
        query: '',
        checkNum: '',
        topicResult: '',
  
        submitStatu: false, // 填空只允许提交一次
        checkMoreArr: [], // 多选选中数组
  
        timeOut: '', // 倒计时
  
        timeUser: 0, // 耗时
  
        exam_idx: 0,
        pair_count: 0,
  
  
        activeNames: [],
        number: ["一", "二", "三", "四", "五", "六", "七", "八", "九"],
  
        xuanXiang: ['A', 'B', 'C', 'D', 'E', 'H', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T'],
  
        text1: '展开步骤',
  
        isSubmit: false, // 该题是否提交过
  
        optionsListHtml: [], // 选项的html列表
  
        showAnswerBtn: false, // 是否展示解答题按钮
  
      }
    },
    props: {
        topicData: {
            type: Object,
            default: {}
        },
        json: {
            type: Object,
            default: {}
        },
        nums: {
          type: Number | String,
          default: 2,
        },
        accu: {
          type: Number || String,
          default: 0
        }
    },
    created () {
      let query = this.$route.query;
      this.query = query;
      $('body,html').animate({scrollTop: 0}, 0);
    },
    mounted () {
        this.isShow = true;
        setTimeout(() => {
            this.showTopic(this.topicData);
        }, 800);
    },
    computed: {
      navInfo () {
        let navInfo = this.$store.state.special.navInfo;
        this.checkMoreArr = [];
        return navInfo;
      },
      topicTotal () {
        return this.$store.state.special.topicTotal || 0;
      },
    },
    watch: {
      showPopover (newValue, oldValue) {
        this.tishiShowList = [];
        this.tishiList = [];
        if (newValue && this.tishiList.length == 0) {
          let topicData = this.topicData;
          let fenbu = topicData[ '提示' ];
          fenbu.map((e, n) => {
            let html = this.$kx.renderToString(e, {
              throwOnError: false
            });
            this.tishiList.push(html)
          });
        }
      },
      topicData (topicData) {
        if (!topicData) {
          return false;
        }
        this.showTopic(this.topicData);
        let fenbu = topicData[ '分步' ];
        if (!fenbu || fenbu.length == 0) {
          return false;
        }
        fenbu.map((e, n) => {
          this.$nextTick().then(() => {
            let dom = document.getElementById('bbuzhou_' + n);
            console.log('dom', dom)
            this.$kx.render(e[ '步骤' ], dom, {
              displayMode: true,
            });
          })
        });
  
      },
      activeNames (val) {
        this.$nextTick(() => {
          console.log(val);
          val.map(e => {
            this.getStep(e);
          });
        });
  
        let list = this.topicData[ '分步' ];
  
        if (!list || list.length == 0) {
          return false;
        }
        let activeNames = [];
        list.map((e, n) => {
          activeNames.push(n);
        });
        if (val.length == activeNames.length) {
          this.text1 = '收起全部'
        } else {
          this.text1 = '展开步骤'
        }
      },
      '$store.state.numTopic' (val) {
        let navInfo = this.$store.state.special.navInfo;
        this.exam_idx = val;
        // this.getDir(navInfo);
      }
    },
    methods: {
      getFenbu(topicData){
        if (!topicData) {
          return false;
        }
        // this.showTopic(this.topicData);
        let fenbu = topicData[ '分步' ];
        if (!fenbu || fenbu.length == 0) {
          return false;
        }
        fenbu.map((e, n) => {
          this.$nextTick().then(() => {
            let dom = document.getElementById('bbuzhou_' + n);
            console.log('dom', dom)
            this.$kx.render(e[ '步骤' ], dom, {
              displayMode: true,
            });
          })
        });
      },
      submitAnswer () {
        this.showAnswerBtn = true
      },
  
      tishi (n) {
        this.tishiShowList.push(n);
        this.tishiShowList = Array.from(new Set(this.tishiShowList));
      },
      shooAllStep () {
        let list = this.topicData[ '分步' ];
        let activeNames = [];
        list.map((e, n) => {
          activeNames.push(n);
        });
        if (this.activeNames.length == activeNames.length) {
          this.activeNames = [];
        } else {
          this.activeNames = activeNames;
        }
      },
      getStep (n) {
        let list = this.topicData[ '分步' ];
        try {
          list[ n ][ '解析' ].map((array, num) => {
            array.map((list_1, num_1) => {
              if (list_1[ 0 ] == 'tex') {
                let id = 'step_list_' + n + '_' + num + '_' + num_1;
                console.log(id);
                this.$nextTick(() => {
                  let dom = document.getElementById(id);
                  this.$kx.render(list_1[ 1 ], dom, {
                    displayMode: true,
                  });
                });
              }
            })
          });
          let dom = document.getElementById('resultJie_' + n);
          let jie = list[ n ][ '解' ];
          // let jie = list[n]['解析'][this.activeNames]['解'];
          console.log('resultJie_' + n, jie);
          this.$kx.render(jie, dom, {
            displayMode: true,
          });
          this.$nextTick(() => {
            let mcloseArr = $('#resultJie_' + n).find('.cjk_fallback');
            $.each(mcloseArr, function (index) {
              let item = mcloseArr[ index ];
              if ($(item).html() == "冇") {
                $(item).html("<input type='text' class='inputClass'>")
              }
            });
          })
        } catch (error) {
  
        }
      },
      // 获取用户当前进度
      user_info_query () {
        let param = {
          book: this.query.book,
          chapter: this.query.name
        };
  
        let navInfo = this.$store.state.special.navInfo;
  
        this.$api.user_info_query({param, method: 'get'}, res => {
          console.log('user_info_query-33333', res);
          let exam_idx = res[ '当前专题索引' ];
          if (this.$store.state.special.this_topic == res[ '当前专题名称' ]) {
            this.exam_idx = exam_idx;
          } else {
            this.exam_idx = 0;
          }
  
          this.init();
        //   this.getDir(navInfo);
          // this.djs(navInfo);
        });
      },
      beforeClose (a, done) {
        this.showSucc = false;
        if (a == 'confirm') { // 下一专题
          this.exam_idx = 0;
          let leftNavInfo;
          
          leftNavInfo = this.$store.state.special.leftNavInfo;
          
          leftNavInfo.childs.map((e, n) => {
            if (e.name == this.navInfo.name) {
              if (n <= leftNavInfo.childs.length) {
                setTimeout(() => {
                  this.$emit('getName', leftNavInfo.childs[ n + 1 ], n)
                }, 10);
              } else {
                location.reload();
              }
            }
          })
          done();
        } else {
          // this.nextTopic();
          done();
        }
      },
      daoxueEvent () {
        this.showDx = true;
        let {book} = this.$route.query;
        let navInfo = this.$store.state.special.navInfo;
        if (navInfo.childs.length > 0) {
          let url = navInfo.childs[ 0 ].solution_dir;
          this.$ajax({
            url,
            success: (res => {
              this.$nextTick(e => {
                let dom = document.getElementById('daoxuanHtml');
                console.log('dom', dom);
                this.$kx.render(res[ '方法' ], dom, {
                  displayMode: true,
                });
              })
            })
          })
        }
      },
      clearDjs () {
        clearInterval(time_id);
        clearInterval(time_ids);
      },
      djs (navInfo) {
        let time_cost = navInfo.childs[ 0 ].time_cost
        time_cost = time_cost * 60;
        this.timeOut = time_cost;
        // timeOut
        time_id = setInterval(() => {
          time_cost --;
          this.timeOut = time_cost;
          if (time_cost == 0) {
            clearInterval(time_id);
          }
        }, 1000);
        time_ids = setInterval(() => {
          this.timeUser ++;
          this.timeOut = time_cost;
        }, 1000);
      },
      goback () {
        this.$store.commit('setFenbu', false);
      },
      goFenbu () {
        let topicData = this.topicData;
        let type = topicData[ '答题方式' ];
        let isKong = false;
        if (type === '填空') {
          let inputClass = $('#topicMain .inputClass');
          inputClass.map((n, e) => {
            if (e.value === '') {
              isKong = true;
            }
          });
          if (isKong) {
            this.$toast('请填写答案！')
            return false;
          }
        } else if (this.checkNum === '') {
          this.$toast('请先选择选项！')
          return false;
        }
  
        if (this.topicResult === '') {
          this.submit();
        }
        this.$store.commit('setFenbu', true);
      },
      init () {
        this.showPopover = false;
        this.aa = false;
        this.topicResult = '';
        this.checkNum = '';
        this.submitStatu = false;
        this.active = '';
        this.topicData = '';
      },
      user_info_write () {
        let param = {
          zhuanti: this.$store.state.special.this_topic,
          dangqianwancheng: '',
          book: this.query.book,
          exam_idx: this.exam_idx,
          chapter: this.query.name
        }
        this.$api.user_info_write({param, method: 'post'}, res => {
          console.log('user_info_write', res);
        });
      },
      // 题 展示
      showTopic (topicData) {
        if (Object.keys(topicData).length > 0) {
          topicData[ '例题' ].map((e, n) => {
            if (e[ 0 ] == 'tex') {
              let dom = document.getElementById('tex_item_' + n);
              if(dom){
                this.$kx.render(e[ 1 ], dom, {
                    displayMode: true,
                });
              }else{
                setTimeout(() => {
                    this.showTopic(this.topicData);
                }, 800);
              }
            }
          })
  
          let type = topicData[ '答题方式' ];
          setTimeout(() => {
  
            switch (type) {
              case '填空':
                //填空题——拿问号变输入框
                let mcloseArr = $('#topicMain').find('.cjk_fallback');
                
                $.each(mcloseArr, function (index) {
                  let item = mcloseArr[ index ];
                  if ($(item).html() == "冇") {
                    $(item).html("<input type='text' class='inputClass'>")
                  }
                });
                console.log('---showTopic 填空', 'muyou')
  
                break;
              case '单选':
                topicData[ '选项' ].map((e, n) => {
                  // let domJie = document.getElementsByClassName('option_span_'+n)[0];
                  // this.$kx.render(e, domJie, {
                  //     displayMode: true,
                  // });
                  let html = this.$kx.renderToString(e, {
                    throwOnError: false
                  });
  
                  this.$set(this.optionsListHtml, n, html)
                })
  
                break;
              case '多选':
                topicData[ '选项' ].map((e, n) => {
                  // let domJie = document.getElementsByClassName('option_span_'+n)[0];
                  // this.$kx.render(e, domJie, {
                  //     displayMode: true,
                  // });
                  let html = this.$kx.renderToString(e, {
                    throwOnError: false
                  });
                  this.optionsListHtml.push(html);
                });
  
                topicData[ '解' ].map((e, n) => {
                  this.$set(this.checkMoreArr, n, {
                    isChecked: false,
                    result: '', // 选中结果
                    answer: e,
                  })
                });
  
                break;
              default:
                break;
            }
  
          }, 200);
  
  
        }
      },
      // 下一题
      nextTopic () {
        console.log('nums', this.nums);
        this.topicData['例题'] = null;
        this.$nextTick(()=>{
          this.topicResult = '';
          this.checkNum = '';
          this.text1 = '展开步骤'
          this.submitStatu = false;
          this.active = '';
          this.isSubmit = false;
          this.$emit('nextTopic')
        })
      },
      // 再试一次
      nextTry () {
        this.topicResult = '';
        this.checkNum = '';
        this.submitStatu = false;
        this.active = '';
        this.showTopic(this.topicData);
      },
  
      //  提交
      submit (answer) {
        let leftInfo;
        this.showPopover = false;
        try {
          leftInfo = JSON.parse(localStorage.leftInfo);
          console.log('leftInfo', leftInfo);
  
        } catch (error) {
  
        }
        let isKong = false;
        var topicResult = '';
        let topicData = this.topicData;
        let jie = topicData[ '解' ];
        let type = topicData[ '答题方式' ];
        if (type === '填空') {
          let dataArr = [],
              inputClass = $('#topicMain .inputClass');
          inputClass.map((n, e) => {
            if (e.value === '') {
              isKong = true;
            }
          });
          if (isKong) {
            this.$toast('请填写答案！')
            return false;
          }
  
          inputClass.map((n, e) => {
            dataArr.push(e.value.toString());
          });
          this.topicData[ "解" ].map((e, i) => {
            inputClass.map((n, el) => {
              if (el.value != e && i == n) {
                if (el.value === '') {
                  el.value = "?"
                }
                topicResult = false;
                $(el).before('<span class="jie-err">' + el.value + '</span>')
              } else if (el.value == e && i == n) {
                topicResult = true;
                $(el).before('<span class="jie-succ">' + e + '</span>');
              }
            });
            inputClass.hide();
            return e.toString()
          })
  
        } else if (type === '单选') {
          if (this.checkNum === '') {
            this.$toast('请先选择选项！')
            return false;
          }
          let jie = topicData[ "解" ];
          if (jie[ this.checkNum ]) {
            this.active = 'succ';
            topicResult = true;
          } else {
            this.active = 'err';
            topicResult = false;
          }
        } else if (type === '多选') {
          topicResult = true;
          this.checkMoreArr.map(e => {
            if (e.isChecked !== e.answer) {
              topicResult = false;
            }
          })
          this.submitStatu = true;
        } else if (type === '解答') {
          topicResult = answer;
          this.submitStatu = true;
          this.showAnswerBtn = false;
        }
  
        this.isSubmit = true;
  
        this.topicResult = topicResult;

        this.getFenbu(this.topicData)

        if(topicResult){
          console.log(111111)
          this.$emit('accuEvent', 1);
        }

        if(this.nums+1 == this.json.data.length){
          
          let param = {
            lunshu: this.json.lunshu,
            nandu: this.json.nandu,
            zsd_idx: this.json.zsd_idx,
            accu: +(this.accu/this.json.data.length).toFixed(2)
          }
          if(topicResult){
            param.accu = +((this.accu+1)/this.json.data.length).toFixed(2)
          }
          this.$api.zsd_stat_write({param}, res=>{
            console.log('res', res)
          })
        }
  
      },
      jindu_write (param) {
        window.$api.jindu2_write({param, method: 'post'}, res => {
          console.log('res', res);
          delete param.obj;
          window.$api.jindu2_query({param, method: 'post'}, res => {
            console.log('res', res);
            let navList = this.$store.state.special.leftNavInfo;
            navList.childs.map(e => {
              e.childs.map(el => {
                if (el.nav_id == res.zhuanti_id) {
                  e.stars_count = res.stars;
                  this.pair_count = res.stars * 20;
                }
              })
              return e;
            });
            this.$store.commit('setleftNavInfo', navList);
            this.jd_q = res;
            if (res.stars == 5) {
              this.schedule_write();
            }
            // if(this.exam_idx == (this.navInfo.childs[0].total-1)){
            //     this.showSucc = true;
            // }
          });
        });
      },
      // 专题完成时写入
      schedule_write () {
        let dir = this.navInfo.childs[ 0 ].dir;
        dir = dir.split('/');
        dir = dir[ dir.length - 1 ];
        let param = {
          is_finish: true,
          book: this.$route.query.book,
          zhuanti: dir,
        }
        this.$api.schedule_write({param, method: 'post'}, res => {
          console.log('schedule_write', res);
        });
      },
      timer_write () {
        let param = {
          spend: this.timeUser,
          timestamp: this.timeUser * 1000,
          book: this.$route.query.book,
          zhuanti: this.navInfo.name,
        }
        this.$api.timer_write({param, method: 'post'}, res => {
          console.log('timer_write', res);
          clearInterval(time_id);
          clearInterval(time_ids);
        });
      },
      // 选择题选项
      optClick (e) {
        let topicData = this.topicData;
        if (this.submitStatu) {
          return false;
        }
        if (topicData[ '答题方式' ] == '单选') {
          this.checkNum = e;
          this.active = 'on';
        } else if (topicData[ '答题方式' ] == '多选') {
          this.checkMoreArr[ e ].isChecked = !this.checkMoreArr[ e ].isChecked;
          console.log('this.checkMoreArr', this.checkMoreArr);
        }
      },
  
      renderExpandInfo (type) {
        let topicData = this.topicData;
        const data = topicData[type] || []
        const containerDom = document.createElement('span');
        data.forEach(str => {
          const contentDom = document.createElement('span');
          if (str[ 0 ] === 'str') {
            contentDom.className = 'tex_item'
            contentDom.innerHTML = str[ 1 ]
          } if (str[ 0 ] === 'img') {
            contentDom.className = 'tex_item'
            contentDom.innerHTML = "<img src=data:image/png;base64," + str[1] + " />"
          } else {
            contentDom.className = 'item_jiexi tex_item'
            contentDom.innerHTML = katex.renderToString(str[ 1 ], {
              throwOnError: false
            });
          }
          containerDom.appendChild(contentDom)
        });
        return containerDom.innerHTML
      },
  
      // 收藏
      handleCollect () {
        this.$emit('collect')
      },
  
      // 取消收藏
      async handleDelCollect () {
        const navInfo = this.$store.state.special.navInfo;
        let dir = navInfo.childs[ 0 ].dir;
        dir = dir.split('/');
        dir = dir[ dir.length - 1 ];
  
        await this.$api.delAdvanceCollection({
          param: {
            book: this.query.book,
            zhuanti: dir,
            exam_idx: this.exam_idx
          },
          method: 'post'
        })
        this.$emit('delCollect')
      },
  
      // 更新lables
      handleUpdateLabels (data) {
        this.topicData.labels = data
      }
    },
    destroyed () {
      // this.user_info_write()
      this.showPopover = false;
    },
  }
  </script>
  
  <style lang="less" scoped>
  .qianghua {
   /deep/ .van-collapse-item__content {
     overflow: scroll;
   }
  
    .title {
      &-p {
        height: 31px;
        font-size: 19px;
        font-family: SourceHanSansSC-Heavy, SourceHanSansSC;
        font-weight: 800;
        color: #FFFFFF;
        line-height: 31px;
        padding-bottom: 12px;
      }
  
      &-check {
        display: flex;
  
        li {
          padding: 5px 12px;
          height: 30px;
          background: #7A8FE1;
          border-radius: 15px;
          margin-right: 8px;
  
          height: 20px;
          font-size: 14px;
          font-family: SourceHanSansSC-Normal, SourceHanSansSC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          position: relative;
  
          &.on {
            background: #F9C200;
            color: #FFFFFF;
          }
  
          .kg {
            &-prop {
              position: absolute;
              z-index: 9;
              top: 45px;
              left: -16px;
              margin: 0 16px;
              width: 343px;
              background: #fff;
              padding: 16px;
              box-sizing: border-box;
              border-radius: 10px;
              color: #2A3155;
              box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.2);
  
              &::before {
                content: '';
                position: absolute;
                top: -10px;
                width: 0;
                height: 0;
                // border-top: 10px solid black;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #fff;
                border-left: 10px solid transparent;
              }
            }
          }
  
          .van-icon {
            position: relative;
            top: 3px;
          }
  
  
        }
      }
  
      &-pair {
        padding-top: 12px;
  
        label {
          height: 20px;
          font-size: 14px;
          font-family: SourceHanSansSC-Normal, SourceHanSansSC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          padding-right: 4px;
        }
  
        &-con {
          width: 269px;
          height: 8px;
          display: inline-block;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 5px;
          overflow: hidden;
  
          span {
            height: 8px;
            float: left;
            background: #F9C200;
          }
        }
      }
    }
  
    #daoxuanHtml {
      overflow: auto;
    }
  
    .goback {
      color: #fff;
  
      .van-icon-arrow-left {
        position: relative;
        top: -2px;
        background: #fff;
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        overflow: hidden;
        border-radius: 25px;
        color: #415FD5;
        box-sizing: border-box;
        font-size: 18px;
        font-weight: bold;
        vertical-align: middle;
        margin-right: 10px;
      }
  
      .txt {
        height: 27px;
        font-size: 16px;
        font-family: SourceHanSansSC-Normal, SourceHanSansSC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 27px;
        display: inline-block;
      }
    }
  
    .shouCang {
      display: flex;
      flex-wrap: wrap;
      padding: 16px;
  
      .sp {
        height: 24px;
        background: #ECF0FF;
        border-radius: 2px;
        border: 1px solid #415FD5;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 3px 8px;
      }
    }
  
    .tishi {
      .sp {
        width: 37px;
        height: 37px;
        display: inline-block;
        background: #415FD5;
        box-shadow: 0px 0px 4px 0px rgba(42, 49, 85, 0.2);
        border-radius: 40px;
        box-sizing: border-box;
        padding: 6px;
  
        .iconfont {
          color: #fff;
          font-size: 20px;
        }
  
        &.on {
          background: #F9C200;
        }
      }
    }
  
    &.on {
      color: #F9C200;
  
      .p2 {
        color: #9498AA;
      }
    }
  
    .kaogang {
      // margin-top: 15px;
      // width: 343px;
      min-height: 96px;
      background: #FFFFFF;
      box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      box-sizing: border-box;
      padding: 12px 16px;
    }
  
    .daoxue {
      border-radius: 15px;
      overflow: hidden;
    }
  
    .content {
      // width: 343px;
      min-height: 157px;
      border-radius: 12px;
      overflow: hidden;
  
      &-top {
        padding: 0 16px;
        padding-top: 15px;
        display: flex;
        justify-content: space-between;
  
        label {
          height: 24px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #606580;
          line-height: 24px;
        }
  
        .right {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #415FD5;
          line-height: 24px;
        }
      }
  
      &-info {
        // width: 343px;
        min-height: 88px;
        // background: #F4F6FD;
        border-radius: 2px;
  
        &-box {
          // padding: 0 20px;
          min-height: 20px;
          color: #606580;
  
          .tibox {
            // width: 343px;
            box-sizing: border-box;
            overflow: auto;
          }
  
          &.err {
            background: rgba(255, 66, 54, 0.08);
            position: relative;
  
            &::after {
              position: absolute;
              bottom: 0;
              right: 10px;
              content: '';
              width: 100px;
              height: 76px;
              background: url('../../../assets/img/img_7.png') no-repeat;
              background-size: 100%;
            }
          }
  
          &.succ {
            background: rgba(21, 195, 146, 0.1);
            position: relative;
  
            &::after {
              position: absolute;
              bottom: 0;
              right: 10px;
              content: '';
              width: 100px;
              height: 76px;
              background: url('../../../assets/img/img_8.png') no-repeat;
              background-size: 100%;
            }
          }
        }
  
        #topicMain {
          text-align: center;
        }
  
        ul {
          // padding-top: 16px;
          // padding-bottom: 8px;
          li {
            // width: 311px;
            margin: 0 auto;
            margin-bottom: 8px;
            text-align: center;
            min-height: 36px;
            line-height: 36px;
            background: #FFFFFF;
            border-radius: 2px;
            border: 1px solid #E6E8EF;
          }
        }
  
        .option {
          padding-top: 12px;
  
          ul {
            // display: flex;
            // flex-wrap:wrap;
            li {
              background: #FFFFFF;
              overflow: auto;
              border-radius: 2px;
              border: 1px solid #E6E8EF;
              text-align: left;
              padding: 0px 12px;
              margin: 12px 16px;
              box-sizing: border-box;
              cursor: pointer;
  
              .option_span {
                display: inline-block;
                margin-left: 5px;
  
                /deep/ .katex-display {
                  margin: 0;
                }
              }
  
              .el-icon-success {
                display: none;
              }
  
              &.on {
                color: #fff;
                background: #415FD5;
                border-radius: 2px;
              }
  
              &.no {
                color: #15C392;
                border-radius: 2px;
              }
  
              &.err {
                // color: #fff;
                color: #FF4236;
                // background: #FF4236;
                border: 1px solid #FF4236;
              }
  
              &.succ {
                // color: #fff;
                color: #15C392;
                // background: #15C392;
                border: 1px solid #15C392;
              }
  
              :hover {
  
              }
            }
  
            &.duoxuan {
              li {
                .el-icon-success {
                  display: none;
                }
  
                &.on {
                  color: #fff;
                  background: #6F9AE1;
                  border: 1px solid #6F9AE1;
                }
  
                &.active {
                  color: #fff;
                  background: #4BD794;
                  border: 1px solid #4BD794;
                }
  
                &.err {
                  color: #fff;
                  background: #F35656;
                  border: 1px solid #F35656;
                }
  
                &.succ {
                  .el-icon-success {
                    display: inline-block;
                    float: right;
                    margin-top: 12px;
                    margin-right: 12px;
                    color: #4BD794;
                    font-size: 20px;
                  }
                }
              }
            }
          }
        }
  
        .btm-con {
          padding-top: 10px;
          margin: 0 20px;
          // background: #fff;
          .btn {
            &-con {
              margin: 0 16px;
              padding-bottom: 20px;
              border-bottom: 1px solid #E6E8EF;
              margin-bottom: 45px;
            }
  
            &-list {
              width: 150px;
              height: 40px;
              // margin: 0 auto;
              line-height: 40px;
              display: block;
              background: #F9C200;
              border-radius: 24px;
  
              font-size: 18px;
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              font-weight: bold;
              color: #FFFFFF;
              text-align: center;
  
              &.sub {
                background: #415FD5;
              }
            }
          }
  
        }
      }
    }
  
    .succ-bbox {
      text-align: center;
  
      .p1 {
        font-size: 14px;
        font-family: SourceHanSansSC-Normal, SourceHanSansSC;
        font-weight: 400;
        color: #9498AA;
        line-height: 20px;
      }
  
      .p2 {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #2A3155;
        line-height: 19px;
        text-align: center;
      }
  
      .p3 {
        padding-top: 5px;
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #415FD5;
        line-height: 31px;
        text-align: center;
  
        span {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #9498AA;
          line-height: 16px;
        }
      }
    }
  
    .iconGroup- {
      font-size: 26px;
    }
  
    .fenbu-con {
      background: #FFFFFF;
      box-shadow: 0px 1px 8px 0px rgba(42, 49, 85, 0.1);
      border-radius: 12px;
      margin: 16px;
      overflow: hidden;
  
      /deep/ .van-collapse-item__content {
        background: #EFF2F7;
      }
  
      .btn-s {
        display: flex;
        justify-content: space-between;
        padding: 16px 16px;
  
        .btn-list {
          width: 191px;
          height: 40px;
          background: #F9C200;
          border-radius: 24px;
          line-height: 40px;
          font-size: 16px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #FFFFFF;
          text-align: center;
          margin-right: 12px;
        }
  
        .btn-list-n {
          width: 104px;
  
          height: 40px;
          background: #415FD5;
          line-height: 40px;
          text-align: center;
          border-radius: 24px;
          font-size: 16px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #FFFFFF;
        }
      }
    }
  }
  
  .nandu {
    padding: 14px 10px 0 18px;
    display: flex;
    align-items: center;
    font-size: 14px;
  
    label {
      padding-right: 10px;
    }
  
    .collect {
      display: flex;
      flex: flow;
      justify-content: center;
      align-items: center;
  
      > p {
        margin-left: 4px;
  
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
      }
    }
    .no {
      color: #415FD5;
    }
    .yes {
      color: #BFC1CC;
    }
  }
  
  .tishi-con {
    min-width: 264px;
    min-height: 22px;
    box-sizing: border-box;
    padding: 15px 12px;
    background: #FFFFFF;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
  
    .p1 {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #606580;
      line-height: 16px;
    }
  
    ul {
      margin-top: 8px;
  
      li {
        background: #FFFFFF;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        padding: 8px 12px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #2A3155;
        line-height: 16px;
        margin-bottom: 5px;
  
        &.on {
          background: url('./../../../assets/img/bg_1.png');
          color: #FFFFFF;
          background-size: cover;
        }
      }
    }
  
    .answer-alert {
      font-family: 'Songti SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #9498AA;
  
    }
  }
  
  .expand-banner {
    padding: 16px 12px;
    margin: 16px;
    background: #FFFFFF;
    box-shadow: 0px 1px 8px rgba(42, 49, 85, 0.1);
    border-radius: 12px;
  
    &__header {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 8px;
  
      > div {
        width: 4px;
        height: 12px;
        background: #415FD5;
        border-radius: 4px;
        margin-right: 9px;
      }
      > p {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        color: #2A3155;
      }
    }
  
    &__desc {
      margin: 0 9px;
      overflow: scroll;
    }
  }
  </style>
  